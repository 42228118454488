import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';
import { cwTheme } from '../theme/theme.js';
import SignUpForm from '../components/SignUpForm';

const PostSection = styled.section`padding: 2rem 0;`;

const PostContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const PostMainImage = styled.div`
	margin: 0 auto;
	display: block;
	max-width: 800px;
	width: 100%;
`;

const PostMeta = styled.div`
	text-align: center;
	max-width: 700px;
	margin: 0 auto;
	width: 100%;
`;

const PostTitle = styled.h2`
	margin-bottom: .5em;
	line-height: 1.2em;
`;

const TagContainer = styled.div`
	margin: 0 auto 1rem auto;
	max-width: 400px;
	width: 100%;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;

	a {
		background-color: ${cwTheme.secondaryBlue};
		padding: 2px 8px;
		border-radius: 16px;
		text-decoration: none;
		color: white;
		font-size: 12px;
		margin-right: 5px;
		margin-bottom: 5px;
	}

	a:hover {
		background-color: ${cwTheme.lightBlue};
	}
`;

const PostBody = styled.div`
	max-width: 680px;
	margin: 0 auto;
	p {
		font-weight: 400;
	}

	img {
		max-width: 100%;
		margin: 2rem 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${cwTheme.sansSerif};
	}
`;

export default ({ data }) => {
	const page = data.contentfulPage;

	const { basePath } = '/';

	const { title, body } = data.contentfulPage;
	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title={title} description={page.metaDescription.childMarkdownRemark.excerpt} />
				<PostSection>
					<PostContainer>
						<PostMeta>
							<PostTitle>{title}</PostTitle>
						</PostMeta>

						<PostBody dangerouslySetInnerHTML={{ __html: body.childContentfulRichText.html }} />
					</PostContainer>
				</PostSection>

				<SignUpForm header={'Receive mindfulness right in your inbox'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($slug: String!) {
		contentfulPage(slug: { eq: $slug }) {
			slug
			title
			body {
				childContentfulRichText {
					html
				}
			}
			metaDescription {
				childMarkdownRemark {
					excerpt(pruneLength: 100)
				}
			}
		}
	}
`;
